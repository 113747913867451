import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/node_modules/smooth-doc/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "loadablecomponent"
    }}><a parentName="h1" {...{
        "href": "#loadablecomponent",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`@loadable/component`}</h1>
    <h2 {...{
      "id": "loadable"
    }}><a parentName="h2" {...{
        "href": "#loadable",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`loadable`}</h2>
    <p>{`Create a loadable component.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Arguments`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`loadFn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The function call to load the component.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional options.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options.resolveComponent`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Function to resolve the imported component from the imported module.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options.fallback`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fallback displayed during the loading.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options.ssr`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If `}<inlineCode parentName="td">{`false`}</inlineCode>{`, it will not be processed server-side. Default to `}<inlineCode parentName="td">{`true`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options.cacheKey`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cache key function (see `}<a parentName="td" {...{
              "href": "/docs/dynamic-import/"
            }}>{`dynamic import`}</a>{`)`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import loadable from '@loadable/component'

const OtherComponent = loadable(() => import('./OtherComponent'))
`}</code></pre>
    <h3 {...{
      "id": "optionsresolvecomponent"
    }}><a parentName="h3" {...{
        "href": "#optionsresolvecomponent",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a><inlineCode parentName="h3">{`options.resolveComponent`}</inlineCode></h3>
    <p>{`This is a function that receives the imported module (what the `}<inlineCode parentName="p">{`import()`}</inlineCode>{` call resolves to) and the props, and returns the component.`}</p>
    <p>{`The default value assumes that the component is exported as a default export.
It can be customized to make a loadable component where the imported component is not the default export, or even where a different export is chosen depending on the props.
For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// components.js

export const Apple = () => 'Apple!'
export const Orange = () => 'Orange!'
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// loadable.js

const LoadableApple = loadable(() => import('./components'), {
  resolveComponent: (components) => components.Apple,
})

const LoadableOrange = loadable(() => import('./components'), {
  resolveComponent: (components) => components.Orange,
})

const LoadableFruit = loadable(() => import('./components'), {
  resolveComponent: (components, props) => components[props.fruit],
})

`}</code></pre>
    <p><strong parentName="p">{`Note:`}</strong>{` The default `}<inlineCode parentName="p">{`resolveComponent`}</inlineCode>{` breaks Typescript type inference due to CommonJS compatibility.
To avoid this, you can specify `}<inlineCode parentName="p">{`resolveComponent`}</inlineCode>{` as `}<inlineCode parentName="p">{`(imported) => imported.default`}</inlineCode>{`.
This requires that the imported components have ES6/Harmony exports.`}</p>
    <h2 {...{
      "id": "lazy"
    }}><a parentName="h2" {...{
        "href": "#lazy",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`lazy`}</h2>
    <p>{`Create a loadable component "Suspense" ready.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Arguments`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`loadFn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The function call to load the component.`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { lazy } from '@loadable/component'

const OtherComponent = lazy(() => import('./OtherComponent'))
`}</code></pre>
    <h2 {...{
      "id": "loadablecomponent-1"
    }}><a parentName="h2" {...{
        "href": "#loadablecomponent-1",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`LoadableComponent`}</h2>
    <p>{`A component created using `}<inlineCode parentName="p">{`loadable`}</inlineCode>{` or `}<inlineCode parentName="p">{`lazy`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Props`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fallback`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fallback displayed during the loading.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`...`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Props are forwarded as first argument of `}<inlineCode parentName="td">{`loadFn`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "loadablecomponentpreload"
    }}><a parentName="h2" {...{
        "href": "#loadablecomponentpreload",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`LoadableComponent.preload`}</h2>
    <p>{`Triggers the loading of a component.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Arguments`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`args`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Props passed to the load function.`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import loadable from '@loadable/component'

const OtherComponent = loadable(() => import('./OtherComponent'))

OtherComponent.preload()
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`This method does not return a Promise intentionally. Use `}<inlineCode parentName="p">{`load`}</inlineCode>{` if you need to wait for the component to be loaded.`}</p>
    </blockquote>
    <h2 {...{
      "id": "loadablecomponentload"
    }}><a parentName="h2" {...{
        "href": "#loadablecomponentload",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`LoadableComponent.load`}</h2>
    <p>{`Force the loading of a component synchronously, returns a Promise.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Arguments`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`args`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Props passed to the load function.`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import loadable from '@loadable/component'

const OtherComponent = loadable(() => import('./OtherComponent'))

OtherComponent.load().then(() => {
  console.log('Component is loaded!')
})
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`If you don't need to know when the component will be loaded, you should use `}<inlineCode parentName="p">{`preload`}</inlineCode>{` instead.`}</p>
    </blockquote>
    <h2 {...{
      "id": "loadablelib"
    }}><a parentName="h2" {...{
        "href": "#loadablelib",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`loadable.lib`}</h2>
    <p>{`Create a loadable library.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Arguments`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`loadFn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The function call to load the component.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional options.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options.resolveComponent`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Function to resolve the imported component from the imported module.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options.fallback`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fallback displayed during the loading.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options.ssr`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If `}<inlineCode parentName="td">{`false`}</inlineCode>{`, it will not be processed server-side. Default to `}<inlineCode parentName="td">{`true`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options.cacheKey`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cache key function (see `}<a parentName="td" {...{
              "href": "/docs/dynamic-import"
            }}>{`dynamic import`}</a>{`)`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import loadable from '@loadable/component'

const Moment = loadable.lib(() => import('moment'))
`}</code></pre>
    <h2 {...{
      "id": "lazylib"
    }}><a parentName="h2" {...{
        "href": "#lazylib",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`lazy.lib`}</h2>
    <p>{`Create a loadable library "Suspense" ready.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Arguments`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`loadFn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The function call to load the component.`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { lazy } from '@loadable/component'

const Moment = lazy.lib(() => import('moment'))
`}</code></pre>
    <h2 {...{
      "id": "loadablelibrary"
    }}><a parentName="h2" {...{
        "href": "#loadablelibrary",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`LoadableLibrary`}</h2>
    <p>{`A component created using `}<inlineCode parentName="p">{`loadable.lib`}</inlineCode>{` or `}<inlineCode parentName="p">{`lazy.lib`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Props`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`children`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Function called when the library is loaded.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ref`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Accepts a ref, populated when the library is loaded.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fallback`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fallback displayed during the loading.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`...`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Props are forwarded as first argument of `}<inlineCode parentName="td">{`loadFn`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "loadableready"
    }}><a parentName="h2" {...{
        "href": "#loadableready",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`loadableReady`}</h2>
    <p>{`Wait for all loadable components to be loaded. This method must only be used with Server Side Rendering, see `}<a parentName="p" {...{
        "href": "/docs/server-side-rendering/"
      }}>{`Server Side Rendering guide`}</a>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Arguments`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`done`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Function called when all components are loaded.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional options.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options.namespace`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Namespace of your application (use only if you have several React apps on the same page).`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { loadableReady } from '@loadable/component'

loadableReady(() => {
  const root = document.getElementById('main')
  hydrate(<App />, root)
})
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      